/// <reference path="o365.pwa.declaration.shared.dexie.objectStores.Index.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as IndexModule from 'o365.pwa.declaration.shared.dexie.objectStores.Index.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const indexedDBHandlerImportmapEntry = self.o365.getImportMapEntryFromImportUrl('o365.pwa.modules.sw.IndexedDBHandler.ts');

    class Index implements IndexModule.Index {
        static objectStoreDexieSchema: string = "&[appId+databaseId+objectStoreId+id], [appId+databaseId+objectStoreId], [appId+databaseId], appId, databaseId, objectStoreId, id";

        public id!: string;
        public appId!: string;
        public databaseId!: string;
        public objectStoreId!: string;
        public keyPath!: string | Array<string> | null;
        public isPrimaryKey!: boolean;
        public isUnique!: boolean;
        public isMultiEntry!: boolean;
        public isAutoIncrement!: boolean;

        public get schema(): string {
            let indexSchema = '';

            if (this.isAutoIncrement) {
                indexSchema += '++';
            }

            if (this.isUnique) {
                indexSchema += '&';
            }

            if (this.isMultiEntry) {
                indexSchema += '*';
            }

            if (Array.isArray(this.keyPath)) {
                indexSchema += `[${this.keyPath.join('+')}]`;
            } else if (typeof this.keyPath === 'string') {
                indexSchema += this.keyPath;
            }

            return indexSchema;
        }

        constructor(
            id: string,
            appId: string,
            databaseId: string,
            objectStoreId: string,
            keyPath: string | Array<string> | null,
            isPrimaryKey: boolean = false,
            isUnique: boolean = false,
            isMultiEntry: boolean = false,
            isAutoIncrement: boolean = false
        ) {
            if (isPrimaryKey === false && keyPath === null) {
                throw new Error('Only primary key can have its keypath(s) ommitted');
            }

            if (Array.isArray(keyPath)) {
                keyPath.sort();
            }

            this.id = id;
            this.appId = appId;
            this.databaseId = databaseId;
            this.objectStoreId = objectStoreId;
            this.keyPath = keyPath;
            this.isPrimaryKey = isPrimaryKey;
            this.isUnique = isUnique;
            this.isMultiEntry = isMultiEntry;
            this.isAutoIncrement = isAutoIncrement;
        }

        public async save(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
            
            await IndexedDBHandler.updateIndex(this);
        }

        public async delete(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
            
            await IndexedDBHandler.deleteIndex(this);
        }

        public async forceReload(): Promise<Index | null> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
            
            return await IndexedDBHandler.getIndexFromIndexedDB(this.appId, this.databaseId, this.objectStoreId, this.id);
        }
    }
    self.o365.exportScripts({ Index });
})();
